import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Icon, Modal, OptionList, Spinner, TextContainer, TextField} from '@shopify/polaris';
import {SearchMajor} from '@shopify/polaris-icons';

const ConditionSelector = (props) => {
  const {
    type,
    index,
    orIndex,
    conditionValue,
    setResource,
    getResources,
    resources,
    title,
    activatorTitle,
    loading
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [searchResource, setSearchResource] = useState(null);
  const [selectedResource, setSelectedResource] = useState([]);

  useEffect(() => {
    if (type === 'market') {
      if (conditionValue) {
        setSelectedResource(conditionValue.map(({id}) => id));
      } else {
        setSelectedResource([]);
      }
    }
  }, [type, conditionValue, setSelectedResource]);

  const handleSearchCustomerTag = useCallback((newValue) => setSearchResource(newValue), []);

  const handleModalChange = useCallback(() => {
    if (!modalVisible) {
      getResources()
    }
    setModalVisible(!modalVisible)
  }, [modalVisible, getResources, setModalVisible]);

  // Add useCallback
  const saveResource = (index, orIndex) => {
    switch (type) {
      case 'customerTag':
        setResource(index, orIndex, selectedResource)
        break;
      case 'market':
        const newValue = selectedResource.map((id) => {
          return {
            marketName: resources.find(({value}) => id === value).label,
            id,
          }
        })

        setResource(index, orIndex, newValue)
        break;
      default:
        break;
    }
    setModalVisible(!modalVisible);
  };

  const activator = useMemo(() => {
    let title = `Select ${activatorTitle}`;

    if (conditionValue) {
      title = type !== 'market'
        ? `${conditionValue?.tag} | Edit`
        : Array.isArray(conditionValue) && conditionValue.length > 1
          ? `${conditionValue.length} markets`
          : conditionValue[0]?.marketName;
    }

    return (
      <div className='mockInputContainer'>
        <div className={'mockInput noBottomMargin'} onClick={handleModalChange}>
          <span>{title}</span>
        </div>
      </div>
    )
  }, [conditionValue, activatorTitle]);

  return (
    <div>
      <Modal
        activator={activator}
        open={modalVisible}
        onClose={handleModalChange}
        title={`Select ${title}`}
        primaryAction={{
          content: 'Save',
          onAction: () => saveResource(index, orIndex),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleModalChange,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {(loading && !resources) ?
              <Spinner accessibilityLabel="Loading Tags" size="large"/>
              :
              resources ?
                <div>
                  {
                    type !== 'market' &&
                    <TextField
                      label={`Search ${title}`}
                      labelHidden
                      prefix={
                        <Icon
                          source={SearchMajor}
                          color="base"
                        />
                      }
                      autoComplete="off"
                      value={searchResource}
                      onChange={handleSearchCustomerTag}
                    />
                  }
                  <OptionList
                    title={`Available ${title}s`}
                    onChange={setSelectedResource}
                    options={resources}
                    selected={selectedResource}
                    allowMultiple={type === 'market'}
                  />
                </div>
                :
                false
            }
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default ConditionSelector;